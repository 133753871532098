const DocMenuConfig = [
  {
    pages: [
      {
        heading: "Ürün Açma, Kapama",
        route: "/openclose",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Yorumlar",
        route: "/comments",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Opsiyonlar",
        route: "/options",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Fiyat Güncelleme",
        route: "/productprice",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Hesap Bilgileri",
        route: "/account/overview",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      
      }
    ],
  },
];

export default DocMenuConfig;
